const Down = ({ onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M11.0673 11.0301C11.8523 11.8362 13.1477 11.8362 13.9327 11.0301L17.3999 7.47028C18.1847 6.66449 19.4794 6.66414 20.2646 7.46952L20.6392 7.85373C21.3963 8.63028 21.3966 9.86878 20.6397 10.6456L13.9325 17.5297C13.1475 18.3355 11.8525 18.3355 11.0675 17.5297L4.3603 10.6456C3.60344 9.86878 3.60366 8.63028 4.3608 7.85372L4.73539 7.46952C5.52063 6.66414 6.81534 6.66449 7.60015 7.47028L11.0673 11.0301Z"
        fill="white"
      />
    </svg>
  );
};

export default Down;
