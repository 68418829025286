import tw, { styled } from 'twin.macro';

export const FavoriteModalWrapper = styled.div`
  ${tw`flex items-center flex-col bg-white gap-[1.75rem] rounded-[1.563rem] w-fit h-fit py-[2rem] lg:py-[2.5rem] px-[2.5rem] lg:px-[3.5rem]
  font-semibold	text-2xl leading-[1.816rem] -tracking-[0.03em]`};
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.55);
`;

export const AnimatedWrapper = styled.div(({ visible }) => [
  tw`absolute transition-all duration-700 ease-in-out opacity-0`,
  visible && tw`opacity-100`,
]);

export const Wrapper = styled.div(() => [
  tw` flex items-center justify-center w-[22.75rem] h-[22.813rem]`,
]);

export const HeartWrapper = styled.div(() => [
  tw`w-[14.25rem] h-[14.25rem] rounded-full bg-[#F4F4F4] flex items-center justify-center`,
]);

export const AnimatedHeart = styled.div(({ visible, $rotate }) => [
  ((visible && $rotate) || (!visible && $rotate)) &&
    `
    -webkit-animation: animateThis 800ms, rotation 800ms  ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-delay: 800ms, 1.5s;
  `,
  ` opacity: 0;
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 50px;
    margin: auto;
 
@keyframes animateThis {
  0% {
    width:0px;
    height:0px; 
    opacity: 0;

  }
  50% {
    width:180px;
    height:174px;
    opacity: 0.5;
  }
  100% {
    width:80px;
    height:74px;
    opacity: 1;
  }
}

@keyframes rotation {
  0% {
    transform: translate(0) rotate(0deg);
  }
  25% {
    transform: translate(11.25vw, calc(-50vh + 555px)) rotate(-90deg);
  }
  50% {
    transform: translate(22.5vw, calc(-50vh + 555px)) rotate(-180deg);
  }
  75% {
    transform: translate(33.75vw, calc(-50vh + 370px)) rotate(-270deg);
  }
  100% {
    transform: translate(45vw, calc(-50vh + 185px)) rotate(-360deg);
  }
}
`,
]);
