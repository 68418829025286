const Sound = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2592 3.69136C11.7076 3.87709 12 4.31466 12 4.80001V19.2C12 19.6854 11.7076 20.1229 11.2592 20.3087C10.8108 20.4944 10.2947 20.3917 9.95148 20.0485L5.50296 15.6H2.40001C1.73728 15.6 1.20001 15.0627 1.20001 14.4V9.60001C1.20001 8.93726 1.73728 8.40001 2.40001 8.40001H5.50296L9.95148 3.95148C10.2947 3.60828 10.8108 3.50562 11.2592 3.69136Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7515 8.75143C15.2201 8.28281 15.9799 8.28281 16.4485 8.75143L18 10.3029L19.5515 8.75143C20.0201 8.28281 20.7799 8.28281 21.2485 8.75143C21.7171 9.22007 21.7171 9.97986 21.2485 10.4485L19.697 12L21.2485 13.5514C21.7171 14.02 21.7171 14.7799 21.2485 15.2485C20.7799 15.7171 20.0201 15.7171 19.5515 15.2485L18 13.697L16.4485 15.2485C15.9799 15.7171 15.2201 15.7171 14.7515 15.2485C14.2829 14.7799 14.2829 14.02 14.7515 13.5514L16.303 12L14.7515 10.4485C14.2829 9.97986 14.2829 9.22007 14.7515 8.75143Z"
        fill="white"
      />
    </svg>
  );
};

export default Sound;
