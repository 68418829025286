const Language = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_815_2596)">
        <path
          d="M8.66937 8.5625H8.26812L7.51831 12.3125H9.41919L8.66937 8.5625Z"
          fill={color ? color : 'black'}
        />
        <path
          d="M23.8359 16.0625C24.2407 17.1499 24.7791 18.0302 25.375 18.7792C25.9709 18.0302 26.5718 17.1499 26.9766 16.0625H23.8359Z"
          fill={color ? color : 'black'}
        />
        <path
          d="M29.1875 5.6875H17.5448L19.9724 25.1674C20.0154 25.9642 19.7976 26.7143 19.2776 27.3016L15.1664 32H29.1875C30.7384 32 32 30.7384 32 29.1875V8.5625C32 7.01163 30.7384 5.6875 29.1875 5.6875ZM29.1875 16.0625H28.9371C28.4037 17.7739 27.5578 19.1131 26.6786 20.1689C27.3673 20.7985 28.1038 21.315 28.8359 21.8935C29.2397 22.2167 29.3056 22.8062 28.9815 23.2109C28.6589 23.615 28.067 23.6805 27.6641 23.3565C26.8685 22.7286 26.1218 22.2029 25.375 21.5177C24.6283 22.2029 23.944 22.7286 23.1484 23.3565C22.7455 23.6805 22.1536 23.615 21.831 23.2109C21.5069 22.8062 21.5728 22.2167 21.9766 21.8935C22.7088 21.315 23.3827 20.7985 24.0714 20.1689C23.1923 19.1132 22.4088 17.774 21.8754 16.0625H21.625C21.1068 16.0625 20.6875 15.6432 20.6875 15.125C20.6875 14.6068 21.1068 14.1875 21.625 14.1875H24.4375V13.25C24.4375 12.7318 24.8568 12.3125 25.375 12.3125C25.8932 12.3125 26.3125 12.7318 26.3125 13.25V14.1875H29.1875C29.7057 14.1875 30.125 14.6068 30.125 15.125C30.125 15.6432 29.7057 16.0625 29.1875 16.0625Z"
          fill={color ? color : 'black'}
        />
        <path
          d="M15.2603 2.46369C15.0854 1.05925 13.8851 0 12.4698 0H2.8125C1.26163 0 0 1.26163 0 2.8125V23.5625C0 25.1134 1.26163 26.375 2.8125 26.375C8.41687 26.375 12.4486 26.375 17.5969 26.375C17.8704 26.0624 18.0997 25.8666 18.1094 25.4586C18.1117 25.3563 15.2729 2.56519 15.2603 2.46369ZM11.4965 17.9192C10.9994 18.0215 10.4958 17.6987 10.3933 17.184L9.79412 14.1875H7.14344L6.54425 17.184C6.44356 17.6912 5.95463 18.0244 5.44106 17.9192C4.93388 17.8176 4.60425 17.3241 4.70587 16.816L6.58081 7.441C6.66869 7.00338 7.05325 6.6875 7.5 6.6875H9.4375C9.88425 6.6875 10.2688 7.00338 10.3567 7.441L12.2317 16.816C12.3333 17.3241 12.0037 17.8176 11.4965 17.9192Z"
          fill={color ? color : 'black'}
        />
        <path
          d="M10.9538 28.25L11.1147 29.5363C11.2219 30.3977 11.7943 31.2762 12.7358 31.7148C14.5125 29.7589 13.4376 30.9422 15.8832 28.25H10.9538Z"
          fill={color ? color : 'black'}
        />
      </g>
      <defs>
        <clipPath id="clip0_815_2596">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Language;
