const Stop = ({ onClick  }) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M7.06977 20.6571C7.06977 22.5032 5.57302 24 3.7269 24C1.88079 24 0.384033 22.5032 0.384033 20.6571V3.34287C0.384033 1.49675 1.88079 0 3.7269 0C5.57302 0 7.06977 1.49675 7.06977 3.34287V20.6571Z"
        fill="white"
      />
      <path
        d="M19.6155 20.6571C19.6155 22.5032 18.1187 24 16.2726 24C14.4265 24 12.9297 22.5032 12.9297 20.6571V3.34287C12.9303 1.49675 14.427 0 16.2726 0C18.1187 0 19.6155 1.49675 19.6155 3.34287V20.6571Z"
        fill="white"
      />
    </svg>
  );
};

export default Stop;
