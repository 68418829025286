import BottomLeftActions from 'Components/Actions/BottomLeftActions';
import BottomRightActions from 'Components/Actions/BottomRightActions';
import TopAtions from 'Components/Actions/TopAtions';
import FavoriteModal from 'Components/FavoriteModal';
import { Wrapper } from 'Components/ImageSwiper/ImageSwiper.styles';
import Modal from 'Components/Modal';
import SharePanel from 'Components/SharePanel';
import { useMemo, useRef, useState } from 'react';
import {
  ActionsBottom,
  ActionsTop,
  FavoriteWrapper,
  ImageWrapper,
} from './Image.styles';
import Spinner from 'Components/Icons/Spinner';
import i18next from 'i18next';

function Image({ story, state, stories }) {
  const [status, setStatus] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [share, setShare] = useState(false);
  const [openFavoriteModal, setOpenFavoriteModal] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  let vid = useRef(null);

  const playPauseHandle = (action) => {
    status ? action('play') : action('pause');
    setStatus(!status);
    // if it's a video
    if (story.isVideo) {
      if (status) {
        vid.current.play();
      } else {
        vid.current.pause();
      }
    }
  };

  const shareHandle = (action) => {
    setShare(true);
    playPauseHandle(action);
  };

  const descriptionClick = (action) => {
    setVisible(!visible);
    if (story?.description[[i18next.language]]) {
      playPauseHandle(action);
    }
  };

  const onNext = (visibleIndex, setSelectedIndex) => {
    if (visibleIndex.current < state?.storiesLength - 1) {
      const i = visibleIndex.current + 1;
      visibleIndex.current = i;
      setSelectedIndex(i);
    }
  };

  const onPrevious = (visibleIndex, setSelectedIndex) => {
    if (visibleIndex.current >= 0) {
      const i = visibleIndex.current - 1;
      visibleIndex.current = i;
      setSelectedIndex(i);
    }
  };

  const onFavorite = (action, setFavorite, favorite) => {
    const found = favorite.includes(story);
    if (found) {
      const tab = favorite.filter((elmt) => elmt !== story);
      setFavorite(tab);
    } else favorite.push(story);
    setOpenFavoriteModal(true);
    setRotate(true);
    setIsClicked(!isClicked);
    action('pause');
    setTimeout(() => {
      setOpenFavoriteModal(false);
    }, 2000);

    setTimeout(() => {
      setRotate(false);
      action('play');
    }, 2350);
  };

  const toBigger = (setBigger, bigger) => {
    setBigger(!bigger);
  };

  const storiesUrl = useMemo(() => {
    if (stories) {
      const storiesIds = stories.map((story) => story.id);
      const baseUrl = window.location.protocol + '//' + window.location.host;
      const idsUrl = encodeURI(JSON.stringify(storiesIds));
      return `${baseUrl}/shared/${idsUrl}`;
    } else {
      return '';
    }
  }, [stories]);

  const videoLoaded = (action) => {
    setLoaded(true);
    vid.current
      .play()
      .then(() => {
        action('play');
      })
      .catch(() => {
        // setMuted(true);
        vid.current.play().finally(() => {
          action('play');
        });
      });
  };

  return (
    <>
      <Modal
        isOpen={share}
        closeModal={() => {
          setShare(false);
          playPauseHandle(state.action);
        }}
      >
        <SharePanel link={storiesUrl} />
      </Modal>
      <FavoriteWrapper visible={openFavoriteModal}>
        <FavoriteModal
          title="Saved to your favorites"
          visible={openFavoriteModal}
          rotate={rotate}
        />
      </FavoriteWrapper>
      <Wrapper>
        {story.isVideo ? (
          <>
            <video
              ref={vid}
              style={{ width: '100%', height: '100%' }}
              src={story.src}
              onLoadedData={() => videoLoaded(state.action)}
              muted={state.muted}
              autoPlay
            />
            {!loaded && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  background: 'rgba(0, 0, 0, 0.9)',
                  zIndex: 9,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#ccc',
                }}
              >
                {/* <div>Loading...</div> */}
                <Spinner />
              </div>
            )}
          </>
        ) : (
          <ImageWrapper src={story.src} alt={story.src} />
        )}
        <ActionsTop isBigger={state.bigger}>
          <TopAtions
            text={story?.description}
            favoriteTab={state?.favoriteTab}
            change={isClicked}
            descriptionClick={() => descriptionClick(state.action)}
            visible={visible}
          />
        </ActionsTop>
        <ActionsBottom isBigger={state.bigger}>
          <BottomLeftActions
            text={story?.title}
            playIcon={status}
            onClick={() => playPauseHandle(state.action)}
            favorite={state.favorite}
            favoriteTab={state?.favoriteTab}
            story={story}
          />
          <BottomRightActions
            favoriteTab={state?.favoriteTab}
            story={story}
            changeColor={isClicked}
            shareClick={() => shareHandle(state.action)}
            previousClick={() =>
              onPrevious(
                state?.state.visibleIndex,
                state?.state.setSelectedIndex,
              )
            }
            nextClick={() =>
              onNext(state?.state.visibleIndex, state?.state.setSelectedIndex)
            }
            heartClick={() =>
              onFavorite(
                state.action,
                state?.setFavoriteTab,
                state?.favoriteTab,
              )
            }
            biggerClick={() => toBigger(state.setBigger, state.bigger)}
            soundClick={() => state.setMuted(!state.muted)}
            favorite={state.favorite}
            muted={state.muted}
            bigger={state.bigger}
          />
        </ActionsBottom>
      </Wrapper>
    </>
  );
}

export default Image;
