const Repeat = ({ color }) => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.924 20.672L12.714 16.98C12.194 17.1359 11.648 17.214 11.076 17.214C7.64396 17.214 4.86196 14.432 4.86196 11C4.86196 7.56797 7.64396 4.78597 11.076 4.78597C14.352 4.78597 17.056 7.35998 17.264 10.584H13.39L19.37 20.126L25.35 10.584H21.476C21.2679 5.01998 16.666 0.573975 11.076 0.573975C5.32996 0.573975 0.649956 5.25397 0.649956 11C0.649956 16.746 5.32996 21.426 11.076 21.426C12.428 21.426 13.728 21.166 14.924 20.672H14.924Z"
        fill={color ? color : "#E0FF22"}
      />
    </svg>
  );
};

export default Repeat;
