import tw, { styled } from "twin.macro";

export const Wrapper = styled.div(() => [
  tw`relative !w-full !h-screen bg-black px-[2rem] pt-[2rem]`,
]);


export const ActionsTop = tw.div`
absolute z-10 right-4 top-4
`;
export const ActionsBottom = tw.div`
absolute z-10 flex items-end justify-between inset-x-4 bottom-4
`;


