const Copy = () => {
  return (
    <svg
      width="19"
      height="23"
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.125 0.25H8.375C6.4625 0.25 5 1.7125 5 3.625V4.75H3.875C1.9625 4.75 0.5 6.2125 0.5 8.125V19.375C0.5 21.2875 1.9625 22.75 3.875 22.75H10.625C12.5375 22.75 14 21.2875 14 19.375V18.25H15.125C17.0375 18.25 18.5 16.7875 18.5 14.875V3.625C18.5 1.7125 17.0375 0.25 15.125 0.25ZM11.75 19.375C11.75 20.05 11.3 20.5 10.625 20.5H3.875C3.2 20.5 2.75 20.05 2.75 19.375V8.125C2.75 7.45 3.2 7 3.875 7H10.625C11.3 7 11.75 7.45 11.75 8.125V19.375ZM16.25 14.875C16.25 15.55 15.8 16 15.125 16H14V8.125C14 6.2125 12.5375 4.75 10.625 4.75H7.25V3.625C7.25 2.95 7.7 2.5 8.375 2.5H15.125C15.8 2.5 16.25 2.95 16.25 3.625V14.875Z"
        fill="#3D3D3D"
      />
    </svg>
  );
};

export default Copy;
