import PlayArrow from 'Components/Icons/PlayArrow';
import Stop from 'Components/Icons/Stop';
import WhiteHeart from 'Components/Icons/WhiteHeart';
import PropTypes from 'prop-types';
import {
  IconWrapper,
  BottomLeftActionsWrapper,
} from './BottomLeftActions.styles';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

function BottomLeftActions({
  text,
  onClick,
  playIcon,
  favorite,
  favoriteTab,
  story,
}) {
  const found = favoriteTab.includes(story);
  return (
    <BottomLeftActionsWrapper>
      {text[i18next.language] && (
        <IconWrapper>
          {(favorite || found) && <WhiteHeart color={'#EC4949'} />}
          {text[i18next.language]}
        </IconWrapper>
      )}
      <IconWrapper onClick={onClick}>
        {!playIcon ? <Stop /> : <PlayArrow />}
      </IconWrapper>
    </BottomLeftActionsWrapper>
  );
}

BottomLeftActions.propTypes = {
  text: PropTypes.string,
};

export default withTranslation()(BottomLeftActions);
