import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

export const socials = [
  {
    name: 'telegram',
    ShareButton: TelegramShareButton,
    Icon: TelegramIcon,
  },
  {
    name: 'linkedIn',
    ShareButton: LinkedinShareButton,
    Icon: LinkedinIcon,
  },
  {
    name: 'whatsapp',
    ShareButton: WhatsappShareButton,
    Icon: WhatsappIcon,
  },
  {
    name: 'email',
    ShareButton: EmailShareButton,
    Icon: EmailIcon,
  },
  {
    name: 'facebook',
    ShareButton: FacebookShareButton,
    Icon: FacebookIcon,
  },
  {
    name: 'twitter',
    ShareButton: TwitterShareButton,
    Icon: TwitterIcon,
  },
];
