import tw, { styled } from "twin.macro";

export const StyledDropdownSecond = styled.div(() => [
  tw``,
]);

export const List = 
  tw.div`w-[8.25rem] flex flex-col absolute justify-center items-center focus:outline-none
 rounded-[0.313rem] bg-[#00000065] p-1 lg:top-0 lg:-right-[8.75rem] lg:left-auto -left-[10rem] -top-[0.125rem]`


export const ListItem = styled.li(({ active }) => [
  tw`flex justify-center items-center cursor-pointer w-full p-[0.75rem] rounded-[0.313rem]
  font-medium text-[0.938rem] leading-[1.322rem] tracking-[-3%] capitalize gap-[1rem] text-white
  `,
  active && tw`bg-[#E0FF22] text-black`,
]);

export const Label = tw.span``;

