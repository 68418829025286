const Share = ({ onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M24 6.71472C24 6.54068 23.9107 6.38168 23.7598 6.29162L13.9253 0.080839C13.762 -0.0211263 13.5587 -0.0271821 13.3894 0.0657981C13.223 0.152821 13.1152 0.326856 13.1152 0.515855L13.1245 2.61017C13.0937 2.61915 13.0659 2.62521 13.0351 2.63712C12.8472 2.69709 12.6624 2.76019 12.4468 2.82611C11.2456 3.24619 10.0782 3.80123 8.96636 4.4853C6.85044 5.79064 4.95001 7.59369 3.49316 9.6912C2.07638 11.7195 1.03836 14.0628 0.49322 16.4571C-0.0426784 18.7703 -0.141239 21.2277 0.194432 23.5619C0.231428 23.817 0.450097 24 0.714982 24C0.976758 24 1.19855 23.817 1.23553 23.5619C1.54353 21.4437 2.24265 19.4004 3.253 17.6542C4.21399 15.995 5.49212 14.5398 6.95827 13.4507C8.34117 12.4246 9.91509 11.7135 11.4982 11.3985C12.0372 11.2904 12.5947 11.2305 13.1615 11.2095L13.1676 13.0277C13.1676 13.2107 13.2722 13.3848 13.4448 13.4718C13.6111 13.5648 13.8175 13.5528 13.9807 13.4508L23.7629 7.14399C23.9106 7.04779 24 6.88879 24 6.71474L24 6.71472Z"
        fill="white"
      />
    </svg>
  );
};

export default Share;
