import tw, { styled } from 'twin.macro';

export const ImageWrapper = styled.img`
  ${tw`w-full h-full  object-cover`};
`;

export const ActionsTop = styled.div(({ isBigger }) => [
  tw`absolute z-10 right-0 lg:right-4 top-[3rem] lg:top-4`,
  isBigger &&
    `
    opacity: 0;
    filter: alpha(opacity=40);
    transition: opacity 1s;
    &:hover{
      opacity: 1.0;
    filter: alpha(opacity=100);
    }
`,
]);

export const ActionsBottom = styled.div(({ isBigger }) => [
  tw`absolute z-10 flex items-end justify-between w-full lg:w-auto lg:inset-x-4 bottom-4 gap-4
 `,
  `@media only screen and (max-width: 900px) {
    &>div:nth-child(1) {
      margin-left: 8px;
    }}`,
  isBigger &&
    `
    opacity: 0;
    filter: alpha(opacity=40);
    transition: opacity 1s;
    &:hover{
      opacity: 1.0;
    filter: alpha(opacity=100);
     }
    `,
]);

export const Wrapper = styled.div(() => [tw`relative !w-full h-screen`]);

export const FavoriteWrapper = styled.div(({ visible }) => [
  tw`flex items-center justify-center w-full  h-screen absolute z-50
transition-transform duration-700 ease-in-out pointer-events-none
`,
  visible &&
    `
    -webkit-animation: moveTobottom 0.5s  ease-in;
    -webkit-animation-fill-mode: forwards;
    @keyframes moveTobottom {
        0% {
            opacity: 0;
            transform: translateY(-100px);
            }
            100% {
            opacity: 1;
            transform: translate(0);
            }
    }
`,
]);
