import { Menu } from '@headlessui/react';
import {
  StyledDropdownSecond,
  List,
  ListItem,
  Label,
} from './DropdownSecond.styles';
import i18next from 'i18next';
import { locales } from 'i18n';
import Language from 'Components/Icons/Language';
import { useTranslation } from 'react-i18next';
import Language2 from 'Components/Icons/Language2';
import { useEffect, useState } from 'react';
import useBreakpoint from 'hooks/useBreakpoint';

const DropdownSecond = () => {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(true);
  const bp = useBreakpoint();

  // get breakPoint for mobile version
  useEffect(() => {
    bp === 'xs' || bp === 'sm' || bp === 'md'
      ? setShowContent(false)
      : setShowContent(true);
  }, [bp]);

  return (
    <StyledDropdownSecond>
      <Menu as="div" className="second">
        <Menu.Button>
          {showContent ? <Language color="white" /> : <Language2 />}
        </Menu.Button>
        <Menu.Items>
          <List>
            {locales.map((item, index) => (
              <Menu.Item key={index}>
                <ListItem
                  active={i18next.language === item}
                  onClick={() => {
                    i18next.changeLanguage(item);
                  }}
                >
                  <Label>{t(item)}</Label>
                </ListItem>
              </Menu.Item>
            ))}
          </List>
        </Menu.Items>
      </Menu>
    </StyledDropdownSecond>
  );
};

export default DropdownSecond;
