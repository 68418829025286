import Gallery from 'Components/Gallery';
import { REMOVE_FROM_FILTER_TAGS } from 'config/constants';
import React, { useEffect, useState } from 'react';
import { getImage, getTags } from 'services';

function Categories() {
  const [categorieData, setCategorieData] = useState();

  //Get Data
  useEffect(() => {
    getTags().then((result) => {
      const filteredTags = result?.data.filter((tag) =>
        REMOVE_FROM_FILTER_TAGS.includes(tag?.slug),
      );
      CategorieGridData(filteredTags);
    });
    // eslint-disable-next-line
  }, []);

  //Categorie Grid Data
  const CategorieGridData = (categories) => {
    if (categories) {
      setCategorieData(
        categories?.map((data) => {
          return {
            id: data?.id,
            src: getImage(data?.slug),
            title: data?.name,
            slug: data?.slug,
          };
        }),
      );
    }
  };
  return <>{categorieData && <Gallery items={categorieData} />}</>;
}

export default Categories;
