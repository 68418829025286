import tw from 'twin.macro';

// padding: 0;
// max-height: 900px;
// display: flex;
// flex-direction: column;
// justify-content: space-between;
// overflow-y: visible;
export const Main = tw.section`px-[1rem]  h-screen flex  flex-col justify-between overflow-y-visible bg-black`;

// z-index: 1;
// display: flex;
// flex-direction: row;
// justify-content: center;
// width: 100%;
// height: 100%;
// position: relative;
// overflow: hidden;
// gap: 2rem;

export const GalleryWrapper = tw.div`flex justify-center gap-8 w-full h-full relative overflow-hidden z-10`;

// display: flex;
//   flex: 1;
//   flex-direction: column;
//   width: 100%;
//   align-self: flex-start;
//   justify-self: flex-start;

// align-self: flex-end;
// justify-self: flex-end;
// justify-content: flex-end;

export const Col = tw.div`flex flex-col w-full self-start justify-self-center z-10 [&>*:nth-child(2)]:(self-end justify-end justify-self-end) gap-[1.25rem] lg:gap-[2rem]`;

// width: 100%;
// padding: 1rem;

//hover
// z-index: 99999999999 !important;

// transition: 0.3s ease-out;
// overflow: hidden;
// width: 100%;
// border-radius: 20px;
// box-shadow: rgba(0,0,0,.15) 3px 3px 20px;
export const Image = tw.img`w-full h-full object-cover object-center cursor-pointer transition duration-[0.3s] ease-in-out transform rounded-2xl `;

export const ImageWrapper = tw.div`w-full p-4 hover:(scale-110 z-50)`;

export const CategorieWrapper = tw.div`
fixed z-50 bottom-[1.25rem] lg:bottom-[2.5rem] left-4 lg:left-0
w-full lg:flex lg:justify-center
`;

export const DropDownWrapper = tw.div`
fixed z-[100] left-4 lg:left-[1.625rem] top-4 lg:top-auto lg:bottom-[2.313rem]
`;

export const LogoWrapper = tw.div`
fixed z-[100] right-4 lg:left-[1.625rem] top-4 lg:top-[2.313rem] cursor-pointer
`;
