import Button from 'Components/Button';
import { useTranslation } from 'react-i18next';
import {
  TitleWrapper,
  EndModalWrapper,
  ButtonWrapper,
} from './EndModal.styles';

function EndModal({ rePlayClick, moreClick, favorite }) {
  const { t } = useTranslation();
  return (
    <EndModalWrapper>
      <TitleWrapper>{t('endSlide')}</TitleWrapper>
      <ButtonWrapper>
        <Button
          variant="secondary"
          label={t('watch')}
          onClick={rePlayClick}
          favorite={favorite}
        />
        <Button
          variant="primary"
          label={t('discover')}
          onClick={moreClick}
          favorite={favorite}
        />
      </ButtonWrapper>
    </EndModalWrapper>
  );
}

export default EndModal;
