import { InstaStoriesWrapper } from './InstaStories.styles';
import Stories from 'react-insta-stories';
import Image from 'Components/Image';
import { useState } from 'react';

function InstaStories({ stories, state, favorite }) {
  let storiesLength = stories?.length;
  const [bigger, setBigger] = useState(false);
  const [muted, setMuted] = useState(true);

  const [favoriteTab, setFavoriteTab] = useState([]);
  const onStoryStart = (index) => {
    state?.setReSwipe(false);
    state.visibleIndex.current = index;
  };

  const renderStories = () => {
    let preview = stories?.map((story) => {
      return {
        content: ({ action, isPaused }) => {
          return (
            <Image
              story={story}
              stories={stories}
              state={{
                action,
                state,
                storiesLength,
                setBigger,
                bigger,
                favorite,
                favoriteTab,
                setFavoriteTab,
                muted,
                setMuted,
              }}
            />
          );
        },
        duration: story?.duration,
        defaultInterval: story?.duration,
      };
    });
    return preview;
  };

  return (
    <InstaStoriesWrapper isBigger={bigger} favorite={favorite}>
      <Stories
        stories={renderStories()}
        // defaultInterval={7000}
        width={'100%'}
        height={'100%'}
        onAllStoriesEnd={(s, st) => {
          state?.setEndSwipe(true);
          state?.setSelectedIndex(storiesLength - 1);
          state.visibleIndex.current = storiesLength - 1;
        }}
        onStoryStart={onStoryStart}
        currentIndex={state.selectedIndex}
        loop={state.reSwipe}
        preventDefault
      />
    </InstaStoriesWrapper>
  );
}

export default InstaStories;
