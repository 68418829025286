import { PLACES_API, TAGS_API } from 'config/constants';

export async function getTags() {
  return await fetch(TAGS_API).then((response) => response.json());
}

export async function getPlaces() {
  return await fetch(PLACES_API).then((response) => response.json());
}

export const getImage = (slug) => {
  switch (slug.toLowerCase()) {
    // case 'gastronomie':
    //   return 'https://res.cloudinary.com/casbah/image/upload/v1677665675/BMK-inspiring_mode/Gastronomie_sm4ck0.jpg';
    // case 'agence-de-voyages':
    //   return 'https://res.cloudinary.com/casbah/image/upload/v1677665678/BMK-inspiring_mode/AgenceDeVoyage_ru16nu.jpg';
    // case 'infos-pratiques':
    //   return 'https://res.cloudinary.com/casbah/image/upload/v1677665678/BMK-inspiring_mode/InfosPratiques_jpy2sc.jpg';
    // case 'sejour-deal':
    //   return 'https://res.cloudinary.com/casbah/image/upload/v1677665678/BMK-inspiring_mode/SejourDeal_gntjvz.jpg';
    // case 'hebergement-deal':
    //   return 'https://res.cloudinary.com/casbah/image/upload/v1677665675/BMK-inspiring_mode/HebergementDeal_pjqzwx.jpg';
    // case 'hebergement':
    //   return 'https://res.cloudinary.com/casbah/image/upload/v1677665675/BMK-inspiring_mode/Hebergement_yjggrp.jpg';
    // default:
    //   return 'https://res.cloudinary.com/casbah/image/upload/v1677665678/BMK-inspiring_mode/InfosPratiques_jpy2sc.jpg';
    case 'evasion-montagnarde':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692886978/BMK-inspiring_mode/NEW%20TAGS/Evasion%20montagnarde.jpg';
    case 'vers-les-chemins-de-la-randonnee':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692886275/BMK-inspiring_mode/NEW%20TAGS/Vers%20les%20chemins%20de%20la%20randonn%C3%A9e.jpg';
    case 'authenticite-gastronomique':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692886241/BMK-inspiring_mode/NEW%20TAGS/Authenticit%C3%A9%20gastronomique.jpg';
    case 'tresors-locaux':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692886269/BMK-inspiring_mode/NEW%20TAGS/Tr%C3%A9sors%20locaux.jpg';
    case 'au-fil-des-eaux':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692886275/BMK-inspiring_mode/NEW%20TAGS/Au%20fil%20des%20eaux.jpg';
    case 'pique-nique-au-bord-du-lac':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692886240/BMK-inspiring_mode/NEW%20TAGS/Pique-nique%20au%20bord%20du%20lac.jpg';
    case 'activites-et-loisirs':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692884815/BMK-inspiring_mode/NEW%20TAGS/Activit%C3%A9s%20et%20Loisirs.jpg';
    case 'decouvertes-insolites':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692886566/BMK-inspiring_mode/NEW%20TAGS/D%C3%A9couvertes%20insolites.jpg';
    case 'nature-et-environnement':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692887077/BMK-inspiring_mode/NEW%20TAGS/Nature%20et%20Environnement.jpg';
    case 'artisanat-local':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692886239/BMK-inspiring_mode/NEW%20TAGS/Artisanat%20local.jpg';
    case 'geologie-et-paleontologie':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692884887/BMK-inspiring_mode/NEW%20TAGS/G%C3%A9ologie%20et%20Pal%C3%A9ontologie.jpg';
    case 'musique':
      return 'https://res.cloudinary.com/casbah/image/upload/v1692886702/BMK-inspiring_mode/NEW%20TAGS/Musique.jpg';
    default:
      return 'https://res.cloudinary.com/casbah/image/upload/v1692886702/BMK-inspiring_mode/NEW%20TAGS/Musique.jpg';
  }
};
