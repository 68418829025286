import tw, { styled } from 'twin.macro';

export const BottomLeftActionsWrapper = styled.div`
  ${tw` flex flex-col-reverse lg:flex-row gap-2 lg:gap-4 text-white font-bold text-base lg:text-2xl leading-[1.41rem] lg:leading-[2.116rem] -tracking-[0.03em]
  `};
`;

export const IconWrapper = styled.div(() => [
  tw`flex items-center justify-center bg-[#00000065] gap-[0.5rem] lg:gap-[1.25rem] rounded-[0.813rem] w-fit h-[3.438rem] lg:h-[4.5rem] p-4 lg:p-6 cursor-pointer capitalize`,
  `border: 1px solid #7c7c7c;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);`,
]);
