import tw, { styled } from 'twin.macro';

export const EndModalWrapper = styled.div`
  ${tw`absolute z-50 w-full h-screen flex items-center justify-center flex-col bg-[#00000060] gap-[3rem] px-[1.188rem] lg:px-0
  font-bold	text-[2rem] lg:text-5xl leading-[2.821rem] lg:leading-[4.231rem] -tracking-[0.03em] text-white capitalize`};


`;

export const TitleWrapper = styled.div(() => [
  tw`max-w-[19.563rem] lg:max-w-[35.938rem] flex items-center justify-center text-center`,
]);

export const ButtonWrapper = styled.div(() => [
  tw`flex items-center justify-center flex-col lg:flex-row gap-[1.25rem] w-full`,
]);
