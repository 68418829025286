import tw, { styled } from 'twin.macro';

export const CardWrapper = styled.div(() => [
  tw`relative w-full max-h-[15.75rem] lg:max-h-[40.625rem] min-h-[12.938rem] lg:min-h-[37.5rem]  rounded-[1.565rem] overflow-hidden cursor-pointer`,
  `&:hover ${SelectItem}{
visibility: visible;
}
&:hover ${CardImage}{
  width: 120%;
  height: 110%;
}
`,
]);

export const CardTitle = styled.span`
  ${tw` absolute bottom-0 flex justify-center items-center h-[3.188rem] lg:h-[5.438rem] w-full z-20 bg-[#020202] bg-opacity-[0.65] rounded-b-[1.565rem]  capitalize
text-white font-bold lg:text-[2rem] text-xs leading-[1.21rem] lg:leading-[2.421rem] -tracking-[0.03em] text-center
`};
`;

export const SelectItem = styled.div(({checked}) => [
  tw`invisible  absolute w-full h-full bg-[#00000040] rounded-[1.565rem] text-center
flex justify-center items-center flex-col gap-[0.563rem] lg:gap-[2.188rem] transition-all
`,
checked && `
visibility: visible;
`
]);

export const CardImage = tw.img`
absolute w-full h-full object-cover rounded-[1.565rem]
transition-all duration-1000 ease-in-out
`;

export const StyledButton = styled.input(() => [
  tw`relative w-[2.5rem] lg:w-[3.813rem] h-[2.5rem] lg:h-[3.813rem] rounded-[0.875rem] cursor-pointer
    flex justify-center items-center`,

  ` 
   -webkit-appearance: none;
  appearance: none;
  border: 4px solid #FFFFFF;
  outline: none;
  cursor: pointer;

  :default {
    border: 4px solid #000000;
    background: #F5B12F;
  }
  :disabled {
    cursor: default;
  }
  :default:after  {
    content:'\\2714';
    font-size: 35px;
    position: absolute;
    color: #000000;
}

  
  `,
]);
export const LabelWrapper = tw.span`
text-white font-medium text-base lg:text-2xl leading-[1.816rem] -tracking-[0.03em] capitalize
text-white font-medium text-base lg:text-2xl leading-[1.21rem] lg:leading-[1.816rem] -tracking-[0.03em] capitalize
`;
