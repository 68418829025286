const WhiteHeart = ({ color , onClick}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={color ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M22.9739 7.93231L22.9783 7.98202L22.9876 8.03096C22.9849 8.01676 22.9852 8.02037 22.9868 8.041C22.997 8.16819 23.0588 8.94269 22.788 10.1847C22.3205 12.3269 20.9102 14.034 19.7815 15.2156L11.9712 22.0296L4.26316 15.2535C2.71563 13.8494 1.67305 12.0994 1.2203 10.1855C0.9154 8.88584 1.0087 8.09749 1.03024 7.95188L1.03419 7.92516L1.03669 7.89827C1.36682 4.3528 3.85307 1.97031 6.79129 1.97031C8.5216 1.97031 10.0913 2.77684 11.1511 4.17476L11.9369 5.21129L12.7366 4.18549C13.8167 2.80003 15.4555 1.97031 17.2166 1.97031C20.1644 1.97031 22.6561 4.36656 22.9739 7.93231Z"
        stroke={color ? color : 'white'}
        strokeWidth="2"
      />
    </svg>
  );
};

export default WhiteHeart;
