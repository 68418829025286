import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation } from 'swiper';
import {
  CopyButton,
  CopyWrapper,
  Link,
  MediaWrapper,
  NavigationBtn,
  ShareOption,
  SwiperWrapper,
  TextWrapper,
  Wrapper,
} from './SharePanel.styles';
import { socials } from 'utils/constants';
import ChevronLeft from 'Components/Icons/ChevronLeft';
import ChevronRight from 'Components/Icons/ChevronRight';
import Copy from 'Components/Icons/Copy';

function SharePanel({ link }) {
  // eslint-disable-next-line
  const [_, setInit] = useState();
  const { t } = useTranslation();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const inputRef = useRef(null);
  const copyLink = () => {
    if (inputRef.current) {
      const text = inputRef.current.value;
      navigator.clipboard.writeText(text);
    }
  };

  return (
    <Wrapper>
      <ShareOption>
        <TextWrapper> {t('shareLink')}</TextWrapper>
        <CopyWrapper>
          <Link
            aria-label="share link"
            ref={inputRef}
            type="text"
            value={link}
            readOnly
          />
          <CopyButton type="button" onClick={copyLink}>
            <Copy />
          </CopyButton>
        </CopyWrapper>
      </ShareOption>
      <ShareOption>
        <TextWrapper>{t('shareVia')}</TextWrapper>
        <SwiperWrapper>
          <NavigationBtn ref={prevRef} position="left">
            <ChevronLeft />
          </NavigationBtn>
          <Swiper
            modules={[A11y, Navigation]}
            slidesPerView="auto"
            spaceBetween={16}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onInit={() => setInit(true)}
          >
            {socials?.map(({ name, ShareButton, Icon, color }) => (
              <SwiperSlide key={name}>
                <ShareButton url={link}>
                  <MediaWrapper>
                    <Icon borderRadius={12} size={68} />
                    <p>{name}</p>
                  </MediaWrapper>
                </ShareButton>
              </SwiperSlide>
            ))}
          </Swiper>
          <NavigationBtn ref={nextRef} position="right">
            <ChevronRight />
          </NavigationBtn>
        </SwiperWrapper>
      </ShareOption>
    </Wrapper>
  );
}

SharePanel.propTypes = {};

export default SharePanel;
