const Top = ({ onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M13.9327 13.9699C13.1477 13.1638 11.8523 13.1638 11.0673 13.9699L7.60015 17.5297C6.81534 18.3355 5.52062 18.3359 4.73539 17.5305L4.36079 17.1463C3.60366 16.3697 3.60344 15.1312 4.3603 14.3544L11.0675 7.47028C11.8525 6.66453 13.1475 6.66453 13.9325 7.47028L20.6397 14.3544C21.3966 15.1312 21.3963 16.3697 20.6392 17.1463L20.2646 17.5305C19.4794 18.3359 18.1847 18.3355 17.3999 17.5297L13.9327 13.9699Z"
        fill="white"
      />
    </svg>
  );
};

export default Top;
