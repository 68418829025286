// src/styles/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

const CustomStyles = createGlobalStyle`
  body {
    ${tw`font-primary`}
  }

  //Dropdown
.menu {
     ${tw`relative inline-block`}
     button {
      ${tw`inline-flex justify-center items-center bg-[#E0FF22] 
      lg:w-[4.75rem] lg:h-[4.75rem] w-[3.688rem] h-[3.688rem] rounded-[0.813rem]
      border border-[#7C7C7C]`}
     }
  }

.second {
     ${tw`relative inline-block`}
     button {
      ${tw`inline-flex justify-center items-center lg:bg-[#00000065] 
      lg:w-[4rem] lg:h-[4rem] lg:rounded-[0.813rem]
      lg:border border-[#7C7C7C]`}
     }
  }


  .toggle-switch {
  position: relative;
  width: 156px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  border: 2px solid #000000;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.32);
  border-radius: 33px;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 50px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 52px;
      padding: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 50px;
      letter-spacing: -0.03em;
      text-transform: capitalize;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      padding-left: 16px;
      background-color: #E0FF22;
    }
  }
  &-inner:after {
    content: attr(data-no);
    padding-right: 17px;
    background-color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 35px;
    height: 36px;
    margin: 5px;
    background: #838383;
    position: absolute;
    top: 3px;
    bottom: 0;
    border: 2px solid #000000;
    border-radius: 33px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
     
    }
    .toggle-switch-switch {
      right: 0px;
      background: #fff;
    }
  }
}

  .marquee-container{
  display: flex;
overflow: visible !important;
  }

  .swiper-slide {
    width: fit-content !important;
    height: fit-content !important;
  }

  .imageSlider{
    width: 100% !important;
    height: 100% !important;
    border-radius: 23px;
  }

  .swiperWrapper{
    width: 100% !important;
    height: -webkit-fill-available !important; 
    padding-bottom: 50px !important;
  }

  .swiper-pagination{
    padding-bottom: 7px !important;
  }

  .swiper-pagination-bullet{
    width: 87px;
    height: 4px;
    background: #595959;
    border-radius: 100px;
  }

  .swiper-pagination-bullet-active{
    background: #E0FF22;
  }
  .swiper-pagination-progressbar-fill{
    background: #E0FF22;
  }
  .swiper-pagination-bullet .prev {
     background: #E0FF22 !important;
}
`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
