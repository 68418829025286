import tw, { styled } from 'twin.macro';

export const SelectedCategoryWrapper = styled.div(({ state }) => [
  tw`relative flex items-center flex-col lg:flex-row bg-white gap-[0.5rem] rounded-[1.25rem] w-[11.5rem] lg:w-[17.111rem] h-[4rem] lg:h-[6.708rem] p-2 lg:p-4
  transition-all duration-700 ease-in-out
  `,
  `box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.54);`,

  state &&
    `
    @media only screen and (min-width: 900px) {
        width: 39.214rem;
    }

    @media only screen and (max-width: 900px) {
        height: 6.625rem;
    }
  `,
]);

export const SelectedCategoryBtn = styled.button(({ state }) => [
  tw`absolute right-2 lg:right-4 bottom-2 lg:bottom-4 bg-[#a9a9a9] rounded-2xl outline-none border-0
  font-bold text-lg lg:text-2xl leading-[2.031rem] lg:leading-[2.708rem] -tracking-[0.03em] capitalize
  flex items-center justify-center gap-[1rem] transition-all duration-700 ease-in-out
  w-[10.5rem] lg:w-[15.188rem] lg:h-[4.688rem] h-[3rem]
  `,
  state && tw`bg-[#E0FF22]`,
]);
export const TitleWrapper = styled.div(({ state }) => [
  tw` font-medium text-sm lg:text-[1.375rem] leading-[1.059rem] lg:leading-[1.664rem] capitalize lg:max-w-full lg:w-3/5 max-w-[8rem] text-center lg:truncate
  transition-all duration-700 ease-in-out origin-left 	flex items-center justify-center
  `,
  ` 
  visibility: hidden; 
  opacity: 0;
`,
  state &&
    `
    opacity: 1;
    visibility: visible;
`,
]);
