import tw, { styled } from "twin.macro";

export const StyledDropdown = styled.div(() => [
  tw``,
]);

export const List = 
  tw.div`w-[8.25rem] flex flex-col absolute justify-center items-center mt-2 focus:outline-none
 rounded-[0.313rem] bg-white p-1 lg:-top-[6.875rem]`


export const ListItem = styled.li(({ active }) => [
  tw`flex justify-center items-center cursor-pointer w-full p-[0.75rem] rounded-[0.313rem]
  font-medium text-[0.938rem] leading-[1.322rem] tracking-[-3%] capitalize gap-[1rem]
  `,
  active && tw`bg-[#E0FF22]`,
]);

export const Label = tw.span``;

