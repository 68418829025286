import Heart from 'Components/Icons/Heart';
import PropTypes from 'prop-types';
import {
  HeartWrapper,
  FavoriteModalWrapper,
  AnimatedHeart,
  Wrapper,
  AnimatedWrapper,
} from './FavoriteModal.styles';

function FavoriteModal({ title, visible, rotate }) {
  return (
    <Wrapper>
      <AnimatedWrapper visible={visible}>
        <FavoriteModalWrapper>
          <HeartWrapper></HeartWrapper>
          {title}
        </FavoriteModalWrapper>
      </AnimatedWrapper>
      <AnimatedHeart visible={visible} $rotate={rotate}>
        <Heart />
      </AnimatedHeart>
    </Wrapper>
  );
}

FavoriteModal.propTypes = {
  title: PropTypes.string,
};

export default FavoriteModal;
