const BiggerIn = ({ onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.59998 10.2L6.49078 10.2C7.47365 10.1971 8.41544 9.80543 9.11043 9.11043C9.80543 8.41544 10.1971 7.47365 10.2 6.49078L10.2 3.59998C10.2 3.12259 10.0103 2.66476 9.67277 2.32719C9.33521 1.98962 8.87737 1.79998 8.39998 1.79998C7.92259 1.79998 7.46476 1.98962 7.12719 2.32719C6.78962 2.66476 6.59998 3.12259 6.59998 3.59998L6.59998 6.49078C6.59998 6.51974 6.58848 6.54752 6.568 6.568C6.54752 6.58848 6.51974 6.59998 6.49078 6.59998L3.59998 6.59998C3.12259 6.59998 2.66476 6.78962 2.32719 7.12719C1.98962 7.46475 1.79998 7.92259 1.79998 8.39998C1.79998 8.87737 1.98962 9.33521 2.32719 9.67277C2.66476 10.0103 3.12259 10.2 3.59998 10.2Z"
        fill="white"
      />
      <path
        d="M17.5092 10.2L20.4 10.2C20.8774 10.2 21.3352 10.0103 21.6728 9.67277C22.0103 9.33521 22.2 8.87737 22.2 8.39998C22.2 7.92259 22.0103 7.46475 21.6728 7.12719C21.3352 6.78962 20.8774 6.59998 20.4 6.59998L17.5092 6.59998C17.4802 6.59998 17.4524 6.58848 17.432 6.568C17.4115 6.54752 17.4 6.51974 17.4 6.49078L17.4 3.59998C17.4 3.12259 17.2103 2.66476 16.8728 2.32719C16.5352 1.98962 16.0774 1.79998 15.6 1.79998C15.1226 1.79998 14.6648 1.98962 14.3272 2.32719C13.9896 2.66476 13.8 3.12259 13.8 3.59998L13.8 6.49078C13.8028 7.47365 14.1945 8.41544 14.8895 9.11044C15.5845 9.80543 16.5263 10.1971 17.5092 10.2Z"
        fill="white"
      />
      <path
        d="M15.6 22.2C16.0774 22.2 16.5352 22.0103 16.8728 21.6728C17.2103 21.3352 17.4 20.8774 17.4 20.4L17.4 17.5092C17.4 17.4802 17.4115 17.4524 17.432 17.432C17.4524 17.4115 17.4802 17.4 17.5092 17.4L20.4 17.4C20.8774 17.4 21.3352 17.2103 21.6728 16.8728C22.0103 16.5352 22.2 16.0774 22.2 15.6C22.2 15.1226 22.0103 14.6648 21.6728 14.3272C21.3352 13.9896 20.8774 13.8 20.4 13.8L17.5092 13.8C16.5263 13.8028 15.5845 14.1945 14.8895 14.8895C14.1945 15.5845 13.8028 16.5263 13.8 17.5092L13.8 20.4C13.8 20.8774 13.9896 21.3352 14.3272 21.6728C14.6648 22.0103 15.1226 22.2 15.6 22.2Z"
        fill="white"
      />
      <path
        d="M3.60001 17.4L6.49081 17.4C6.51977 17.4 6.54755 17.4115 6.56803 17.432C6.58851 17.4524 6.60001 17.4802 6.60001 17.5092L6.60001 20.4C6.60001 20.8774 6.78965 21.3352 7.12722 21.6728C7.46479 22.0103 7.92262 22.2 8.40001 22.2C8.8774 22.2 9.33524 22.0103 9.6728 21.6728C10.0104 21.3352 10.2 20.8774 10.2 20.4L10.2 17.5092C10.1972 16.5263 9.80546 15.5845 9.11046 14.8895C8.41547 14.1945 7.47368 13.8028 6.49081 13.8L3.60001 13.8C3.12262 13.8 2.66479 13.9896 2.32722 14.3272C1.98965 14.6648 1.80001 15.1226 1.80001 15.6C1.80001 16.0774 1.98965 16.5352 2.32722 16.8728C2.66479 17.2103 3.12262 17.4 3.60001 17.4Z"
        fill="white"
      />
    </svg>
  );
};

export default BiggerIn;
