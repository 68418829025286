import tw, { styled } from 'twin.macro';

export const SlidesWrapper = tw.div`
relative overflow-hidden bg-[#000000] w-screen h-screen
`;

export const FavoriteWrapper = styled.div(({ visible }) => [
  tw`flex items-center justify-center w-full  h-screen absolute z-50
transition-transform duration-700 ease-in-out pointer-events-none
`,
  visible &&
    `
    -webkit-animation: moveTobottom 0.5s  ease-in;
    -webkit-animation-fill-mode: forwards;
    @keyframes moveTobottom {
        0% {
            opacity: 0;
            transform: translateY(-100px);
            }
            100% {
            opacity: 1;
            transform: translate(0);
            }
    }
`,
]);

export const DropWrapper = tw.div`
fixed z-[100] left-8 lg:left-[3rem] top-4 lg:top-[3rem] hidden lg:block
`;