import Repeat from 'Components/Icons/Repeat';
import { StyledBtn } from './Button.styles';

const Button = ({ favorite, label, variant, ...rest }) => {
  return (
    <StyledBtn variant={variant} favorite={favorite} {...rest}>
      {variant === 'secondary' && <Repeat color={favorite && '#EC4949'} />}
      {label}
    </StyledBtn>
  );
};

export default Button;
