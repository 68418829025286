const PlayArrow = ({ onClick }) => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 21 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M19.3958 8.88877L4.37434 0.385723C3.94494 0.136821 3.45595 0.0037374 2.95705 0C2.17279 0 1.42065 0.305241 0.8661 0.848573C0.311546 1.3919 0 2.12882 0 2.89721V21.1462C9.9741e-05 21.6561 0.139613 22.1567 0.404033 22.5959C0.668453 23.0351 1.04813 23.3968 1.50358 23.6436C1.95903 23.8903 2.47362 24.013 2.99386 23.9989C3.5141 23.9848 4.02099 23.8344 4.46182 23.5634L19.5008 14.2546C19.9684 13.9679 20.3522 13.5674 20.6149 13.0921C20.8775 12.6168 21.01 12.0829 20.9994 11.5426C20.9888 11.0022 20.8356 10.4737 20.5545 10.0086C20.2735 9.54355 19.8743 9.15773 19.3958 8.88877Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayArrow;
