const Close = ({ onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect
        x="2.00012"
        y="5.30469"
        width="4.67306"
        height="22.197"
        rx="2.33653"
        transform="rotate(-45 2.00012 5.30469)"
        fill="white"
      />
      <rect
        x="17.6957"
        y="2"
        width="4.67306"
        height="22.197"
        rx="2.33653"
        transform="rotate(45 17.6957 2)"
        fill="white"
      />
    </svg>
  );
};

export default Close;
