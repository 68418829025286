import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPlaces } from 'services';
import { DropWrapper, SlidesWrapper } from './Share.styles';
import EndModal from 'Components/EndModal';
import DropdownSecond from 'Components/DropdownSecond';
import InstaStories from 'Components/InstaStories';

export default function Share() {
  const params = useParams();
  const [allPlaces, setAllPlaces] = useState([]);
  const [endSwipe, setEndSwipe] = useState(false);
  const [reSwipe, setReSwipe] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [imageSwiperData, setImageSwiperData] = useState();
  const visibleIndex = useRef(0);

  const navigate = useNavigate();

  useEffect(() => {
    getPlaces().then((result) => {
      setAllPlaces(result.data);
    });
  }, []);

  const storiesArr = useMemo(() => {
    function isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }
    const storiesLink = params.link;
    const idsArr = isJsonString(decodeURI(storiesLink))
      ? JSON.parse(decodeURI(storiesLink))
      : null;

    if (allPlaces?.length > 0) {
      return idsArr?.map((id) => {
        return allPlaces?.filter((place) => place?.id === id)[0];
      });
    } else {
      return null;
    }
  }, [params.link, allPlaces]);

  if (!storiesArr) navigate('/');

  //Image Swiper Data
  const SwiperData = async (places) => {
    if (places) {
      setImageSwiperData(
        await Promise.all(
          places?.map(async (data) => {
            return {
              id: data?.id,
              src: data?.media[0]?.url,
              title: data?.title,
              description: data?.description,
              isVideo: isVideo(data?.media[0]?.url),
              duration: isVideo(data?.media[0]?.url)
                ? await getDuration(data?.media[0]?.url)
                : 7000,
            };
          }),
        ),
      );
    }
  };

  //check if video
  const isVideo = (src) => {
    return src.includes('.mp4');
  };

  //get duration
  const getDuration = async (video) => {
    return new Promise((resolve, reject) => {
      let vid = document.createElement('video');
      vid.src = video;
      vid.onloadedmetadata = function () {
        resolve(vid.duration * 1000);
      };
      vid.onerror = reject;
    });
  };

  const reSwipeHandle = () => {
    setEndSwipe(false);
    setReSwipe(true);
    setSelectedIndex(0);
    visibleIndex.current = 0;
  };

  useEffect(() => {
    if (storiesArr) {
      SwiperData(storiesArr);
    }
    // eslint-disable-next-line
  }, [storiesArr]);

  return (
    <SlidesWrapper>
      {endSwipe && (
        <EndModal
          rePlayClick={() => {
            reSwipeHandle();
          }}
          moreClick={() => navigate('/')}
        />
      )}
      <DropWrapper>
        <DropdownSecond />
      </DropWrapper>
      {imageSwiperData && (
        <InstaStories
          stories={imageSwiperData}
          state={{
            setEndSwipe,
            reSwipe,
            setReSwipe,
            selectedIndex,
            setSelectedIndex,
            visibleIndex,
          }}
        />
      )}
    </SlidesWrapper>
  );
}
