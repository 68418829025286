import { Menu } from '@headlessui/react';
import { StyledDropdown, List, ListItem, Label } from './Dropdown.styles';
import i18next from 'i18next';
import { locales } from 'i18n';
import Language from 'Components/Icons/Language';
import { useTranslation } from 'react-i18next';

const Dropdown = () => {
  const { t } = useTranslation();
  return (
    <StyledDropdown>
      <Menu as="div" className="menu">
        <Menu.Button>
          <Language />
        </Menu.Button>
        <Menu.Items>
          <List>
            {locales.map((item, index) => (
              <Menu.Item key={index}>
                <ListItem
                  active={i18next.language === item}
                  onClick={() => {
                    i18next.changeLanguage(item);
                  }}
                >
                  <Label>{t(item)}</Label>
                </ListItem>
              </Menu.Item>
            ))}
          </List>
        </Menu.Items>
      </Menu>
    </StyledDropdown>
  );
};

export default Dropdown;
