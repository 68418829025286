import tw, { styled } from 'twin.macro';

export const BottomRightActionsWrapper = styled.div`
  ${tw` flex gap-2
  `};
`;
export const IconsSide = styled.div`
  ${tw`flex flex-col gap-2
  `};
`;

export const IconHover = styled.div(({ favorite, rotate }) => [
  tw`hover:bg-[#E0FF22] ease-out duration-700 rounded-[0.813rem] w-[3rem] h-[3rem] cursor-pointer
  flex items-center justify-center 
  `,
  favorite && tw`hover:bg-[#EC4949]`,
  rotate === 'left' && tw`transform lg:-rotate-90`,
  rotate === 'right' && tw`transform lg:-rotate-90`,
]);

export const TransparentIcon = styled.div(({ change }) => [
  tw`w-[3rem] [&>*]:w-[1.5rem] [&>*]:h-[1.5rem] h-[3rem] cursor-pointer flex items-center justify-center   [&>*]:transition-all [&>*]:duration-700 [&>*]:ease-in-out`,
  change && tw`[&>*]:animate-[heart_1s_ease-in-out]`,
  `@keyframes heart {
    0%, 100% {
      width:1.5rem;
      height:1.5rem;
    }
    50% {
      width:3rem;
      height:3rem;
    }
  }`,
]);

export const IconWrapper = styled.div(() => [
  tw`flex items-center justify-around flex-col bg-[#00000065] rounded-l-[0.813rem] lg:rounded-[0.813rem] w-[4rem] py-[0.4rem] gap-[0.5rem]`,
  `border: 1px solid #7c7c7c;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);`,
]);
