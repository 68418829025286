import React, { useEffect, useRef, useState } from 'react';
import { DropWrapper, SlidesWrapper } from './Slides.styles';
import EndModal from 'Components/EndModal';
import InstaStories from 'Components/InstaStories';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPlaces } from 'services';
import DropdownSecond from 'Components/DropdownSecond';

function Slides() {
  const [endSwipe, setEndSwipe] = useState(false);
  const [reSwipe, setReSwipe] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [imageSwiperData, setImageSwiperData] = useState();
  const visibleIndex = useRef(0);
  const { state } = useLocation();
  const navigate = useNavigate();

  //Get Data
  useEffect(() => {
    getPlaces().then((result) => {
      const filteredPlaces = result.data.filter((item) =>
        item.tags
          .flatMap((nestedItem) => nestedItem.slug)
          .some((tag) => state.some((filterTag) => filterTag.slug === tag)),
      );
      const filteredArray = filteredPlaces.filter(
        (obj) => obj?.media && obj.media.length > 0,
      );
      SwiperData(filteredArray);
      if (filteredArray.length === 0) navigate('/');
    });
    if (!state) navigate('/');
    // eslint-disable-next-line
  }, []);

  //Image Swiper Data
  const SwiperData = async (places) => {
    if (places) {
      setImageSwiperData(
        await Promise.all(
          places?.map(async (data) => {
            return {
              id: data?.id,
              src: data?.media[0]?.url,
              title: data?.title,
              description: data?.description,
              isVideo: isVideo(data?.media[0]?.url),
              duration: isVideo(data?.media[0]?.url)
                ? await getDuration(data?.media[0]?.url)
                : 7000,
            };
          }),
        ),
      );
    }
  };

  //check if video
  const isVideo = (src) => {
    return src.includes('.mp4');
  };

  //get duration
  const getDuration = async (video) => {
    return new Promise((resolve, reject) => {
      let vid = document.createElement('video');
      vid.src = video;
      vid.onloadedmetadata = function () {
        resolve(vid.duration * 1000);
      };
      vid.onerror = reject;
    });
  };

  const reSwipeHandle = () => {
    setEndSwipe(false);
    setReSwipe(true);
    setSelectedIndex(0);
    visibleIndex.current = 0;
  };

  return (
    <SlidesWrapper>
      {endSwipe && (
        <EndModal
          rePlayClick={() => {
            reSwipeHandle();
          }}
          moreClick={() => navigate('/')}
        />
      )}
      <DropWrapper>
        <DropdownSecond />
      </DropWrapper>
      {imageSwiperData && (
        <InstaStories
          stories={imageSwiperData}
          state={{
            setEndSwipe,
            reSwipe,
            setReSwipe,
            selectedIndex,
            setSelectedIndex,
            visibleIndex,
          }}
        />
      )}
    </SlidesWrapper>
  );
}

export default Slides;
