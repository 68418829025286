import tw, { styled } from 'twin.macro';

//Object variants
export const buttonsVariant = {
  primary: tw` border-0 text-black border-2 border-solid`,
  secondary: tw`bg-transparent  border-2 border-solid `,
};

export const StyledBtn = styled.button(({ variant, favorite }) => [
  //variants
  ({ variant }) => buttonsVariant[variant],
  variant === 'primary' &&
    (favorite
      ? tw`bg-[#EC4949] border-[#EC4949]`
      : tw`bg-[#E0FF22] border-[#E0FF22]`),
  variant === 'secondary' &&
    (favorite
      ? tw`text-[#EC4949] border-[#EC4949]`
      : tw`text-[#E0FF22] border-[#E0FF22]`),
  tw`outline-none px-8 py-4 rounded-[3.688rem] gap-2 flex items-center justify-center w-full lg:w-fit
  font-bold text-base lg:text-2xl leading-[1.41rem] lg:leading-[2.116rem] -tracking-[0.03em] capitalize`,
]);
