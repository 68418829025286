const Favorite = ({ color }) => {
  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6667 4.09339V22.0001L21.6574 21.9814V22.0001L18.5254 20.2081C18.4643 20.1731 18.4135 20.1226 18.3781 20.0618C18.3428 20.0009 18.3241 19.9318 18.324 19.8614C18.324 19.8614 18.324 9.34006 18.324 9.29473C18.3753 8.57552 18.2692 7.85379 18.0132 7.17973C17.7572 6.50567 17.3575 5.89546 16.8418 5.39156C16.3261 4.88765 15.7068 4.50213 15.027 4.26183C14.3472 4.02152 13.6232 3.9322 12.9054 4.00006H5.95469C5.72269 4.00006 5.65336 3.79739 5.67336 3.56673C5.6771 3.14246 5.772 2.72395 5.95165 2.33957C6.13129 1.95518 6.39146 1.61392 6.71454 1.33889C7.03762 1.06386 7.41605 0.861503 7.82418 0.745531C8.23231 0.62956 8.6606 0.602684 9.08003 0.666725H18.236C18.7017 0.600231 19.1764 0.642989 19.6227 0.791617C20.069 0.940245 20.4745 1.19067 20.8073 1.52308C21.1401 1.85549 21.391 2.26077 21.5402 2.70688C21.6893 3.15298 21.7326 3.62766 21.6667 4.09339ZM16.3334 9.42673V27.3334L8.33336 22.7627L0.333359 27.3334V9.42806C0.266703 8.96238 0.309419 8.48757 0.458121 8.04126C0.606822 7.59495 0.857424 7.18941 1.19007 6.85677C1.52271 6.52412 1.92825 6.27352 2.37456 6.12482C2.82087 5.97612 3.29568 5.9334 3.76136 6.00006H12.9054C13.371 5.9334 13.8458 5.97612 14.2922 6.12482C14.7385 6.27352 15.144 6.52412 15.4767 6.85677C15.8093 7.18941 16.0599 7.59495 16.2086 8.04126C16.3573 8.48757 16.4 8.96238 16.3334 9.42806V9.42673ZM10.2494 10.5734C9.8225 10.5939 9.41164 10.7421 9.07002 10.9989C8.72839 11.2556 8.47176 11.6091 8.33336 12.0134C8.19475 11.6093 7.93801 11.2562 7.59641 10.9997C7.2548 10.7432 6.84406 10.5951 6.41736 10.5747C4.49203 10.5841 3.73469 12.3814 4.08136 14.1747C4.64669 17.0814 8.33336 18.7587 8.33336 18.7587C8.33336 18.7587 12.02 17.0814 12.5854 14.1681C12.932 12.3814 12.1787 10.5841 10.2494 10.5747V10.5734Z"
        fill="white"
      />
    </svg>
  );
};

export default Favorite;
