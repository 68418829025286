export const BASE_URL = `https://api.visitbenimellal.com/cache/`;
export const PLACES_API = BASE_URL + 'places';
export const TAGS_API = BASE_URL + 'tags';
export const Cities_API = BASE_URL + 'city';
export const REMOVE_FROM_FILTER_TAGS = [
  // 'gastronomie',
  // 'agence-de-voyages',
  // 'infos-pratiques',
  // 'sejour-deal',
  // 'hebergement-deal',
  // 'hebergement',

  'evasion-montagnarde',
  'vers-les-chemins-de-la-randonnee',
  'authenticite-gastronomique',
  'tresors-locaux',
  'au-fil-des-eaux',
  'pique-nique-au-bord-du-lac',
  'activites-et-loisirs',
  'decouvertes-insolites',
  'nature-et-environnement',
  'artisanat-local',
  'geologie-et-paleontologie',
  'musique',
];
