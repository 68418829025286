const Bigger = ({ onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M8.39999 1.79999H5.50919C4.52632 1.80284 3.58453 2.19454 2.88953 2.88953C2.19454 3.58453 1.80284 4.52632 1.79999 5.50919V8.39999C1.79999 8.87738 1.98963 9.33521 2.3272 9.67278C2.66476 10.0103 3.1226 10.2 3.59999 10.2C4.07738 10.2 4.53521 10.0103 4.87278 9.67278C5.21035 9.33521 5.39999 8.87738 5.39999 8.39999V5.50919C5.39999 5.48023 5.41149 5.45245 5.43197 5.43197C5.45245 5.41149 5.48023 5.39999 5.50919 5.39999H8.39999C8.87738 5.39999 9.33521 5.21035 9.67278 4.87278C10.0103 4.53521 10.2 4.07738 10.2 3.59999C10.2 3.1226 10.0103 2.66476 9.67278 2.3272C9.33521 1.98963 8.87738 1.79999 8.39999 1.79999Z"
        fill="white"
      />
      <path
        d="M18.4908 1.79999H15.6C15.1226 1.79999 14.6648 1.98963 14.3272 2.3272C13.9896 2.66476 13.8 3.1226 13.8 3.59999C13.8 4.07738 13.9896 4.53521 14.3272 4.87278C14.6648 5.21035 15.1226 5.39999 15.6 5.39999H18.4908C18.5197 5.39999 18.5475 5.41149 18.568 5.43197C18.5885 5.45245 18.6 5.48023 18.6 5.50919V8.39999C18.6 8.87738 18.7896 9.33521 19.1272 9.67278C19.4648 10.0103 19.9226 10.2 20.4 10.2C20.8774 10.2 21.3352 10.0103 21.6728 9.67278C22.0103 9.33521 22.2 8.87738 22.2 8.39999V5.50919C22.1971 4.52632 21.8054 3.58453 21.1104 2.88953C20.4154 2.19454 19.4737 1.80284 18.4908 1.79999Z"
        fill="white"
      />
      <path
        d="M20.4 13.8C19.9226 13.8 19.4648 13.9896 19.1272 14.3272C18.7896 14.6648 18.6 15.1226 18.6 15.6V18.4908C18.6 18.5197 18.5885 18.5475 18.568 18.568C18.5475 18.5885 18.5197 18.6 18.4908 18.6H15.6C15.1226 18.6 14.6648 18.7896 14.3272 19.1272C13.9896 19.4648 13.8 19.9226 13.8 20.4C13.8 20.8774 13.9896 21.3352 14.3272 21.6728C14.6648 22.0103 15.1226 22.2 15.6 22.2H18.4908C19.4737 22.1971 20.4154 21.8054 21.1104 21.1104C21.8054 20.4154 22.1971 19.4737 22.2 18.4908V15.6C22.2 15.1226 22.0103 14.6648 21.6728 14.3272C21.3352 13.9896 20.8774 13.8 20.4 13.8Z"
        fill="white"
      />
      <path
        d="M8.39999 18.6H5.50919C5.48023 18.6 5.45245 18.5885 5.43197 18.568C5.41149 18.5475 5.39999 18.5197 5.39999 18.4908V15.6C5.39999 15.1226 5.21035 14.6648 4.87278 14.3272C4.53521 13.9896 4.07738 13.8 3.59999 13.8C3.1226 13.8 2.66476 13.9896 2.3272 14.3272C1.98963 14.6648 1.79999 15.1226 1.79999 15.6V18.4908C1.80284 19.4737 2.19454 20.4154 2.88953 21.1104C3.58453 21.8054 4.52632 22.1971 5.50919 22.2H8.39999C8.87738 22.2 9.33521 22.0103 9.67278 21.6728C10.0103 21.3352 10.2 20.8774 10.2 20.4C10.2 19.9226 10.0103 19.4648 9.67278 19.1272C9.33521 18.7896 8.87738 18.6 8.39999 18.6Z"
        fill="white"
      />
    </svg>
  );
};

export default Bigger;
