import tw, { styled } from 'twin.macro';

export const TopAtionsWrapper = styled.div`
  ${tw` flex gap-2
  `};
`;

export const InfoWrapper = styled.div(({ visible }) => [
  tw`bg-[#00000065] rounded-[0.813rem] w-fit h-fit max-h-[9.25rem] lg:max-w-[23.125rem] max-w-[17.75rem] overflow-scroll p-6 lg:p-8
  text-white font-normal text-[0.938rem] leading-[1.322rem] -tracking-[0.03em] 
  `,
  `  border: 1px solid #7c7c7c;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);`,
  // `&::-webkit-scrollbar-track {
  //   display:none;
  // }
  // &::-webkit-scrollbar {
  //   display:none;
  // }
  // &::-webkit-scrollbar-thumb {
  //   display:none;
  // }
  // :after {
  //   content: "";
  //   position: absolute;
  // }
  // `,
  visible ? tw`visible` : tw`invisible`,
]);

export const IconsSide = styled.div`
  ${tw`flex flex-col gap-2
  `};
`;

export const TransparentIcon = styled.div`
  ${tw`relative w-[3rem] h-[3rem] cursor-pointer flex items-center justify-center`};
`;

export const IconWrapper = styled.div(() => [
  tw`flex items-center flex-col bg-[#00000065] rounded-l-[0.813rem] lg:rounded-[0.813rem] 
  w-[4rem] py-[0.4rem] gap-[0.5rem]`,
  `border: 1px solid #7c7c7c;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.16);`,
]);

export const FavoriteTag = styled.div(({ change }) => [
  tw`bg-[#EC4949] w-[0.813rem] h-[0.813rem] lg:w-[1.438rem] lg:h-[1.438rem] rounded-full flex items-center justify-center
  font-bold text-[0.43rem] lg:text-[0.761rem] leading-[0.521rem] tracking-[-3%] text-white
  absolute top-[0.4rem] left-[0.5rem] lg:top-0 lg:left-0
  transition-all duration-700 ease-in-out delay-[2000ms]
  `,
  change && tw`animate-[favorite_1s_ease-in-out_2000ms]`,
  `@keyframes favorite {
    0% {
      width:1.438rem;
      height:1.438rem;
      opacity:0;
    }
    50% {
      width:1.8rem;
      height:1.8rem;
    }
   100% {
      width:1.438rem;
      height:1.438rem;
      opacity:1;
    }
  }`,
]);

export const Wrapper = styled.div`
  ${tw`lg:hidden block
  `};
`;
