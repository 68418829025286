const Language2 = ({ color }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_821_2811)">
        <path
          d="M5.14743 5.08398H4.90919L4.46399 7.31055H5.59263L5.14743 5.08398Z"
          fill="white"
        />
        <path
          d="M14.1526 9.53711C14.3929 10.1828 14.7126 10.7054 15.0664 11.1501C15.4202 10.7054 15.7771 10.1827 16.0174 9.53711H14.1526Z"
          fill="white"
        />
        <path
          d="M17.3301 3.37695H10.4173L11.8587 14.9432C11.8842 15.4162 11.7549 15.8616 11.4461 16.2103L9.00513 19H17.3301C18.251 19 19.0001 18.2509 19.0001 17.3301V5.08398C19.0001 4.16315 18.251 3.37695 17.3301 3.37695ZM17.3301 9.53711H17.1815C16.8647 10.5533 16.3625 11.3484 15.8405 11.9753C16.2494 12.3491 16.6867 12.6558 17.1214 12.9993C17.3611 13.1912 17.4003 13.5412 17.2078 13.7815C17.0163 14.0214 16.6648 14.0603 16.4256 13.8679C15.9532 13.4951 15.5098 13.183 15.0665 12.7761C14.6231 13.183 14.2168 13.4951 13.7444 13.8679C13.5052 14.0603 13.1538 14.0214 12.9622 13.7815C12.7698 13.5412 12.8089 13.1912 13.0486 12.9993C13.4834 12.6558 13.8835 12.3491 14.2924 11.9753C13.7705 11.3485 13.3053 10.5533 12.9886 9.53711H12.8399C12.5322 9.53711 12.2833 9.28814 12.2833 8.98047C12.2833 8.67279 12.5322 8.42383 12.8399 8.42383H14.5098V7.86719C14.5098 7.55951 14.7588 7.31055 15.0665 7.31055C15.3741 7.31055 15.6231 7.55951 15.6231 7.86719V8.42383H17.3301C17.6378 8.42383 17.8868 8.67279 17.8868 8.98047C17.8868 9.28814 17.6378 9.53711 17.3301 9.53711Z"
          fill="white"
        />
        <path
          d="M9.06077 1.46281C8.95694 0.62893 8.24429 0 7.40391 0H1.66992C0.74909 0 0 0.74909 0 1.66992V13.9902C0 14.9111 0.74909 15.6602 1.66992 15.6602C4.99752 15.6602 7.39133 15.6602 10.4482 15.6602C10.6106 15.4745 10.7467 15.3583 10.7524 15.116C10.7539 15.0553 9.06831 1.52308 9.06077 1.46281ZM6.82605 10.6395C6.53088 10.7003 6.23189 10.5086 6.17103 10.203L5.81526 8.42383H4.24142L3.88565 10.203C3.82587 10.5041 3.53556 10.702 3.23063 10.6395C2.92949 10.5792 2.73377 10.2862 2.79411 9.9845L3.90736 4.41809C3.95953 4.15825 4.18787 3.9707 4.45312 3.9707H5.60352C5.86877 3.9707 6.09711 4.15825 6.14928 4.41809L7.26256 9.9845C7.3229 10.2862 7.12723 10.5792 6.82605 10.6395Z"
          fill="white"
        />
        <path
          d="M6.50378 16.7734L6.5993 17.5372C6.66298 18.0487 7.00283 18.5702 7.56185 18.8307C8.61679 17.6694 7.97855 18.3719 9.43064 16.7734H6.50378Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_821_2811">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Language2;
