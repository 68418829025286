import React, { useEffect, useRef, useState } from 'react';
import { FavoritesWrapper } from './Favorites.styles';
import EndModal from 'Components/EndModal';
import InstaStories from 'Components/InstaStories';
import { useLocation, useNavigate } from 'react-router-dom';

function Favorites() {
  const [endSwipe, setEndSwipe] = useState(false);
  const [reSwipe, setReSwipe] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const visibleIndex = useRef(0);
  const navigate = useNavigate();
  const { state } = useLocation();

  //Get Data
  useEffect(() => {
    if (!state) navigate('/');
    // eslint-disable-next-line
  }, []);

  const reSwipeHandle = () => {
    setEndSwipe(false);
    setReSwipe(true);
    setSelectedIndex(0);
    visibleIndex.current = 0;
  };

  return (
    <FavoritesWrapper>
      {endSwipe && (
        <EndModal
          rePlayClick={() => {
            reSwipeHandle();
          }}
          moreClick={() => navigate('/')}
          favorite={true}
        />
      )}
      {state && (
        <InstaStories
          stories={state}
          state={{
            setEndSwipe,
            reSwipe,
            setReSwipe,
            selectedIndex,
            setSelectedIndex,
            visibleIndex,
          }}
          favorite={true}
        />
      )}
    </FavoritesWrapper>
  );
}

export default Favorites;
