import Bigger from 'Components/Icons/Bigger';
import Down from 'Components/Icons/Down';
import Share from 'Components/Icons/Share';
import Sound from 'Components/Icons/Sound';
import Top from 'Components/Icons/Top';
import WhiteHeart from 'Components/Icons/WhiteHeart';
import PropTypes from 'prop-types';
import {
  IconsSide,
  IconWrapper,
  BottomRightActionsWrapper,
  IconHover,
  TransparentIcon,
} from './BottomRightActions.styles';
import SoundOn from 'Components/Icons/SoundOn';
import BiggerIn from 'Components/Icons/BiggerIn';

function BottomRightActions({
  shareClick,
  previousClick,
  nextClick,
  heartClick,
  changeColor,
  biggerClick,
  soundClick,
  favorite,
  favoriteTab,
  story,
  muted,
  bigger,
}) {
  const found = favoriteTab.includes(story);

  return (
    <BottomRightActionsWrapper>
      <IconsSide>
        <IconWrapper>
          <TransparentIcon change={changeColor}>
            {!favorite && (
              <WhiteHeart
                color={(changeColor || found) && '#EC4949'}
                onClick={heartClick}
              />
            )}
            {favorite && <WhiteHeart color="#EC4949" />}
          </TransparentIcon>
          <IconHover favorite={favorite} onClick={shareClick}>
            <Share />
          </IconHover>
        </IconWrapper>
        <IconWrapper>
          <IconHover favorite={favorite} onClick={biggerClick}>
            {bigger ? <BiggerIn /> : <Bigger />}
          </IconHover>
          <IconHover favorite={favorite} onClick={soundClick}>
            {muted ? <Sound /> : <SoundOn />}
          </IconHover>
          <IconHover favorite={favorite} onClick={previousClick} rotate={'right'} >
            <Top />
          </IconHover>
          <IconHover favorite={favorite} onClick={nextClick} rotate={'left'}>
            <Down />
          </IconHover>
        </IconWrapper>
      </IconsSide>
    </BottomRightActionsWrapper>
  );
}

BottomRightActions.propTypes = {
  text: PropTypes.string,
};

export default BottomRightActions;
