import PropTypes from 'prop-types';
import {
  SelectedCategoryBtn,
  SelectedCategoryWrapper,
  TitleWrapper,
} from './SelectedCategory.styles';
import Play from 'Components/Icons/Play';
import { useTranslation } from 'react-i18next';

function SelectedCategory({ state, onClick }) {
  const { t } = useTranslation();
  return (
    <SelectedCategoryWrapper state={state.length}>
      <TitleWrapper state={state.length}>
        {t('SelectedCategories') + ' ( ' + state.length + ' ) '}
      </TitleWrapper>
      <SelectedCategoryBtn
        state={state.length}
        disabled={state.length ? false : true}
        onClick={onClick}
      >
        <Play />
        {t('ImReady')}
      </SelectedCategoryBtn>
    </SelectedCategoryWrapper>
  );
}

SelectedCategory.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
};

export default SelectedCategory;
