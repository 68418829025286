import {
  CategorieWrapper,
  Col,
  DropDownWrapper,
  GalleryWrapper,
  LogoWrapper,
  Main,
} from './Gellery.styles';
import React, { useEffect, useState } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import './style.css';
import { useNavigate } from 'react-router-dom';
import useBreakpoint from 'hooks/useBreakpoint';
import Card from 'Components/Card';
import SelectedCategory from 'Components/SelectedCategory';
import Dropdown from 'Components/Dropdown';
import Logo from 'Components/Icons/Logo';

const Gallery = ({ items }) => {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [showContent, setShowContent] = useState(true);
  const [halfWayIndex, setHalfWayIndex] = useState();
  const bp = useBreakpoint();
  const navigate = useNavigate();

  // get breakPoint for mobile version
  useEffect(() => {
    bp === 'xs' || bp === 'sm' ? setShowContent(false) : setShowContent(true);
  }, [bp]);

  // Split array items into 3 columns
  useEffect(() => {
    setHalfWayIndex(
      showContent
        ? Math.floor(items?.length / 3)
        : Math.floor(items?.length / 2),
    );
    // eslint-disable-next-line
  }, [showContent, halfWayIndex]);

  // Click Handler
  const clickHandler = (item) => {
    // search for the item in the selected category in local storage
    const category = JSON.parse(localStorage.getItem('selectedCategory'));
    const found = category?.some((elmt) => elmt.id === item.id);
    if (found) {
      // remove the item from the selected category in local storage
      const updatedCategory = category?.filter((elmt) => elmt.id !== item.id);
      localStorage.setItem('selectedCategory', JSON.stringify(updatedCategory));
      setSelectedCategory(updatedCategory);
    }
    // if the item is not found in the selected category in local storage
    else {
      // add the item to the selected category in local storage
      if (!category) {
        localStorage.setItem('selectedCategory', JSON.stringify([item]));
        setSelectedCategory([item]);
        return;
      }
      const updatedCategory = [...category, item];
      localStorage.setItem('selectedCategory', JSON.stringify(updatedCategory));
      setSelectedCategory(updatedCategory);
    }
  };

  useEffect(() => {
    // clear the selected category from local storage
    localStorage.removeItem('selectedCategory');
  }, []);

  // Render Card Columns
  const renderCardColumns = (array) =>
    array?.length > 0 &&
    array.map((item, index) => (
      <Card
        key={index}
        {...item}
        onChange={() => clickHandler(item)}
        isChecked={selectedCategory?.some((elmt) => elmt.id === item.id)}
        className="card"
      />
    ));

  //animation
  useEffect(() => {
    let chck_if_gsap_loaded = setInterval(function () {
      if (gsap && ScrollTrigger) {
        gsap.registerPlugin(ScrollTrigger);
        animateImages(cols);
        clearInterval(chck_if_gsap_loaded);
      }
    }, 100);
    const cols = gsap.utils.toArray('.col');

    function animateImages(cols) {
      let offset = 0;

      cols.forEach((col, i) => {
        const images = col.childNodes;

        // DUPLICATE IMAGES FOR LOOP
        // images.forEach((image) => {
        //   var clone = image.cloneNode(true);
        //   col.appendChild(clone);
        // });

        // Attach click event listener
        // images.forEach((image) => {
        //   image.addEventListener('click', () => {
        //     // retrive the original image
        //     const original = items.find((item) => item.id === Number(image.id));
        //     clickHandler(original);
        //   });
        // });

        // SET ANIMATION
        images.forEach((item) => {
          let columnHeight = item.parentElement.offsetHeight;
          let direction = i % 2 !== 0 ? '+=' : '-='; // Change direction for odd columns
          let yOffset = direction === '+=' ? -offset : offset;

          let tween = gsap.to(item, {
            y: direction + Number(columnHeight / 2),
            duration: 50,
            repeat: -1,
            ease: 'none',
            onRepeat: () => {
              offset = 0;
            },
            modifiers: {
              y: gsap.utils.unitize((y) => {
                y = (parseFloat(y) + yOffset) % (columnHeight * 0.5);
                return y;
              }),
            },
          });

          // pause animation on mouseover and resume on mouseout for each column
          col.addEventListener('mouseover', () => {
            if (tween) tween.pause();
          });

          col.addEventListener('mouseout', () => {
            if (tween) tween.resume();
          });
        });
      });
    }
  }, []);

  return (
    <Main>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <CategorieWrapper>
        <SelectedCategory
          state={selectedCategory}
          onClick={() => navigate('/slides', { state: selectedCategory })}
        />
      </CategorieWrapper>
      <DropDownWrapper>
        <Dropdown />
      </DropDownWrapper>
      <GalleryWrapper>
        <Col className="col">
          {renderCardColumns(items?.slice(0, halfWayIndex))}
          {renderCardColumns(items?.slice(0, halfWayIndex))}
        </Col>
        <Col className="col">
          {renderCardColumns(items?.slice(halfWayIndex, halfWayIndex * 2))}
          {renderCardColumns(items?.slice(halfWayIndex, halfWayIndex * 2))}
        </Col>
        {showContent && (
          <Col className="col">
            {renderCardColumns(items?.slice(halfWayIndex * 2, items?.length))}
            {renderCardColumns(items?.slice(halfWayIndex * 2, items?.length))}
          </Col>
        )}
      </GalleryWrapper>
    </Main>
  );
};

export default Gallery;
