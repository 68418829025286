import React, { useRef } from 'react';
import {
  CardWrapper,
  CardImage,
  CardTitle,
  SelectItem,
  StyledButton,
  LabelWrapper,
} from './Card.styles';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function Card({ src, title, onChange, isChecked, ...rest }) {
  const { t } = useTranslation();
  const checkboxRef = useRef(null);

  return (
    <CardWrapper htmlFor="checkbox" onClick={onChange} {...rest}>
      <CardImage src={src} />
      {title && <CardTitle>{title[i18next.language]}</CardTitle>}
      <SelectItem checked={isChecked}>
        <StyledButton
          id="checkbox"
          type="checkbox"
          defaultChecked={isChecked}
          // checked={isChecked}
          ref={checkboxRef}
        />
        <LabelWrapper>
          {isChecked ? t('CategorySelected') : t('SelectCategory')}
        </LabelWrapper>
      </SelectItem>
    </CardWrapper>
  );
}

export default Card;
