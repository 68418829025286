import DropdownSecond from 'Components/DropdownSecond';
import Close from 'Components/Icons/Close';
import Favorite from 'Components/Icons/Favorite';
import Info from 'Components/Icons/Info';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  FavoriteTag,
  IconsSide,
  IconWrapper,
  InfoWrapper,
  TopAtionsWrapper,
  TransparentIcon,
  Wrapper,
} from './TopAtions.styles';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

function TopAtions({ text, favoriteTab, change, descriptionClick, visible }) {
  const navigate = useNavigate();

  const toFavorite = () => {
    if (favoriteTab.length > 0) navigate('/favorite', { state: favoriteTab });
  };
  return (
    <TopAtionsWrapper>
      {text[i18next.language] && (
        <InfoWrapper visible={visible}>{text[i18next.language]}</InfoWrapper>
      )}
      <IconsSide>
        <IconWrapper>
          <TransparentIcon>
            <Close onClick={() => navigate('/')} />
          </TransparentIcon>
          <Wrapper>
            <DropdownSecond />
          </Wrapper>
          <TransparentIcon>
            <Info onClick={descriptionClick} color={text[i18next.language] && "#E0FF22"} />
          </TransparentIcon>
        </IconWrapper>
        <IconWrapper>
          <TransparentIcon onClick={() => toFavorite()}>
            {favoriteTab.length > 0 && (
              <FavoriteTag change={change}>{favoriteTab.length}</FavoriteTag>
            )}
            <Favorite />
          </TransparentIcon>
        </IconWrapper>
      </IconsSide>
    </TopAtionsWrapper>
  );
}

TopAtions.propTypes = {
  text: PropTypes.string,
};

export default withTranslation()(TopAtions);
