const Info = ({ color, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      onClick={onClick}
    >
      <path
        d="M11 -2.869e-06C17.0751 -2.3379e-06 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92483 22 -5.10679e-05 17.0751 -5.05368e-05 11C-5.00057e-05 4.92483 4.92488 -3.4001e-06 11 -2.869e-06ZM11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44363 15.5563 2.74997 11 2.74997C6.44363 2.74997 2.74997 6.44362 2.74997 11C2.74997 15.5563 6.44362 19.25 11 19.25Z"
        fill={color ? color : 'white'}
      />
      <path
        d="M11 9.625C11.7594 9.625 12.375 10.2406 12.375 11L12.375 15.125C12.375 15.8844 11.7594 16.5 11 16.5C10.2406 16.5 9.62498 15.8844 9.62498 15.125L9.62498 11C9.62503 10.2406 10.2406 9.625 11 9.625Z"
        fill={color ? color : 'white'}
      />
      <path
        d="M11 5.49998C10.2406 5.49998 9.62498 6.11559 9.62498 6.87499C9.62498 7.63439 10.2406 8.25 11 8.25C11.7594 8.25 12.375 7.63439 12.375 6.87499C12.375 6.11559 11.7594 5.49998 11 5.49998Z"
        fill={color ? color : 'white'}
      />
    </svg>
  );
};

export default Info;
