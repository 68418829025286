const Heart = ({ color }) => {
  return (
    <svg
      // width="80"
      // height="74"
      viewBox="0 0 80 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M79.8997 23.0569C78.7167 9.69566 69.2524 0 57.3888 0C50.4539 0 44.0355 3.29 39.8266 8.72303C35.691 3.23293 29.4926 0 22.6378 0C10.8141 0 1.36656 9.63859 0.136874 22.9294C0.0101882 23.7913 -0.299732 26.8666 0.823415 31.6826C2.49642 38.8026 6.34543 45.2686 11.9907 50.4166L37.704 73.1649C38.3306 73.7216 39.1204 74 39.9035 74C40.6833 74 41.4663 73.725 42.0929 73.175L68.2427 50.2154C72.1017 46.1641 77.437 39.8323 79.2166 31.6257C80.353 26.3805 79.9264 23.1977 79.8997 23.0569Z"
        fill="#EC4949"
      />
    </svg>
  );
};

export default Heart;
