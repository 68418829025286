import tw, { styled } from 'twin.macro';

export const InstaStoriesWrapper = styled.div(({isBigger, favorite}) => [
  tw`relative !w-full !h-screen bg-black lg:p-[2rem]
  transition-all duration-700 ease-out
  `,
  isBigger && `padding: 0px !important;
  &>div>div:nth-child(2) {
    border-radius: unset !important;
    margin-bottom: unset!important;
  }
  &>div>div:nth-child(1) {
    opacity: 0;
    filter: alpha(opacity=40);
    transition: opacity 2s;
    &:hover{
      opacity: 1.0;
    filter: alpha(opacity=100);
     }
  }
  `,

  `&>div>div:nth-child(2) {
  border-radius: 23px;
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 32px;
}

&>div>div:nth-child(3) {
  visibility: hidden !important;
}

&>div>div:nth-child(2)>div {
  width: 100% !important;
  height: 100% !important;
  padding: unset !important;
}

&>div>div:nth-child(2)>div>img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover; !important;
}

&>div:nth-child(1) {
  background: unset !important;
}
&>div>div:nth-child(1) {
  bottom: -3px;
  width: 100% !important;
}

&>div>div:nth-child(1)>div {
  height: 4px !important;
  background: #595959 !important;
}

&>div>div:nth-child(1)>div>div {
  background: #E0FF22 !important;
}

@media only screen and (max-width: 900px) {
  &>div>div:nth-child(2) {
    border-radius: unset;
    margin-bottom: unset!important;
  }

  &>div>div:nth-child(1) {
    bottom: unset;
    top: 20px;
    width: 95% !important;
  }
  
  &>div>div:nth-child(1)>div {
    height: 4px !important;
    background: #F8FFCC !important;
  }
}

`,
favorite && `
&>div>div:nth-child(1)>div>div {
  background: #EC4949 !important;
}

&>div>div:nth-child(1)>div {
  background: #EC494980 !important;
}

`
]);
