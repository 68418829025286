import Categories from 'Pages/Categories';
import Favorites from 'Pages/Favorites';
import Share from 'Pages/Share';
import Slides from 'Pages/Slides';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Categories />} />
        <Route path="/slides" element={<Slides />} />
        <Route path="/favorite" element={<Favorites />} />
        <Route path="shared/:link" element={<Share />} />
        <Route path="*" element={<Categories />} />
      </Routes>
    </div>
  );
}

export default App;
