const SoundOn = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 9.25018C3.44772 9.25018 3 9.69789 3 10.2502V14.7496C3 15.3019 3.44772 15.7496 4 15.7496H7.22222L10.7838 19.4046C11.4099 20.0471 12.5 19.6039 12.5 18.7067V6.29309C12.5 5.39593 11.4099 4.95265 10.7838 5.59519L7.22222 9.25018H4Z"
        fill="white"
      />
      <path
        d="M19.8889 12.5C19.8889 9.36394 18.0312 6.6706 15.3853 5.5174C14.9356 5.32141 14.6111 4.89637 14.6111 4.40583C14.6111 3.68735 15.2839 3.15504 15.9508 3.42243C19.4857 4.83976 22 8.36886 22 12.5C22 16.6287 19.4887 20.156 15.9571 21.575C15.2872 21.8442 14.6111 21.3093 14.6111 20.5873C14.6111 20.0944 14.9372 19.6673 15.389 19.4704C18.033 18.3178 19.8889 15.6346 19.8889 12.5Z"
        fill="white"
      />
      <path
        d="M15.9104 9.10095C16.7453 9.97795 17.25 11.1742 17.25 12.5C17.25 13.825 16.7401 15.0297 15.8975 15.8995C15.3838 16.4298 14.6111 15.9739 14.6111 15.2355V9.76167C14.6111 9.0161 15.3963 8.56094 15.9104 9.10095Z"
        fill="white"
      />
    </svg>
  );
};

export default SoundOn;
