const Play = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C7.1635 0 0 7.1635 0 16C0 24.8365 7.1635 32 16 32C24.8371 32 32 24.8365 32 16C32 7.1635 24.8371 0 16 0ZM16 28.8C8.94206 28.8 3.2 23.0579 3.2 16C3.2 8.94206 8.94206 3.2 16 3.2C23.0579 3.2 28.8 8.94206 28.8 16C28.8 23.0579 23.0579 28.8 16 28.8Z"
        fill="black"
      />
      <path
        d="M21.8195 14.88L14.0601 10.4C12.9933 9.78429 12.1203 10.288 12.1203 11.52V20.48C12.1203 21.712 12.9933 22.2163 14.0601 21.6L21.8195 17.12C22.8864 16.5043 22.8864 15.4957 21.8195 14.88Z"
        fill="black"
      />
    </svg>
  );
};

export default Play;
